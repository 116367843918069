import { DownloadPdfPreferences } from './DownloadPdfPreferences';
import { PeriodicReviewConfig } from './Form';
import { FormType } from './FormTypes';
import { Translations } from './Translation';

export enum ModuleType {
  Default = 0,
  Resource = 1,
  Document = 2,
}

export const ModuleTypeKeys = {
  [ModuleType.Default]: 'common:module-type.default',
  [ModuleType.Document]: 'common:module-type.document',
  [ModuleType.Resource]: 'common:module-type.resource',
} as const;

export type ModuleSummary = {
  id: string;
  moduleNumber: number;
  name: string;
  description: string;
  iconCode: string;
  progress?: number;
  openTasks?: number;
  scheduledTasks?: number;
  nextTaskName?: string | null;
  nextTaskDate?: string | null;
  sortOrder: number;
  isLocked: boolean;
  approvedAssets: number;
  inProgressAssets: number;
  totalAssets: number;
  validatedAssets: number;
  totalHighRiskAssets: number;
  openAssetProgress: number;
  isOpen: boolean;
  totalOpenAssets: number;
};

export interface ModuleTemplate {
  id: string;
  iconCode: string;
  sections: ModuleSection[];
  isLocked: boolean;
  isEnabled?: boolean;
  isOwnedByClient?: boolean;
  translations: Translations<'name' | 'description' | 'prefix'>;
  sortOrder: number;
  clientId: string | undefined;
  requiresValidation?: boolean;
  requiresValidationSignature?: boolean;
  requiresApproval?: boolean;
  requiresApprovalSignature?: boolean;
  requiresOwnerAcknowledgement?: boolean;
  type?: ModuleType;
  periodicReviewConfig?: PeriodicReviewConfig | null;
  downloadPdfPreferences?: DownloadPdfPreferences;
}

export type ModuleSection = {
  id: string;
  forms: ModuleForm[];
  translations: Translations<'name' | 'description'>;
};

export type ModuleForm = {
  templateFormId: string;
  templateFormVersion: number;
};

export type ModuleTemplateForm = {
  id: string;
  sectionId: string;
  version: number;
  isLatest: boolean;
  translations: Translations;
  type: (typeof FormType)[keyof typeof FormType];
  code: string;
  modifiedUtc: string;
  archivedUtc?: string | null;
};

export type ClientModuleTemplateFormInfoResponse = {
  clientModuleId: string;
} & ModuleTemplateForm;
