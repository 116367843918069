import { ClientFormDefault } from './../models/ClientFormDefaults';
import { FormConfig, FormTemplateConfigurationPagedFilter, GetAllTemplatesRequest } from './../models/Form';
import { ApiResponse } from '../models/ApiResponse';
import { ModuleTemplate, ModuleTemplateForm, ModuleType } from '../models/Module';
import TemplateModuleServiceBase from './TemplateModuleServiceBase';
import { MoveTemplateFormInModulesRequest } from '../models/MoveTemplateFormInModulesRequest';
import { ClientModuleTemplateDefault, ClientTemplateModuleDefaultResponse, ModuleDefaultConfiguration } from '../models/ClientModuleDefaults';

class ClientTemplateModuleService extends TemplateModuleServiceBase {
  _clientId: string;

  constructor(clientId: string) {
    super();
    this._clientId = clientId;
  }

  // #region Builder API

  public createTemplate(data: ModuleTemplate): Promise<ApiResponse<ModuleTemplate>> {
    return TemplateModuleServiceBase.create(`/v1/clients/${this._clientId}/template/modules`, data);
  }

  public updateTemplate(moduleId: string, data: ModuleTemplate): Promise<ApiResponse<ModuleTemplate>> {
    return TemplateModuleServiceBase.update(`/v1/clients/${this._clientId}/template/modules/${moduleId}`, data);
  }

  public getTemplates(isLocked?: boolean, isEnabled?: boolean, types?: ModuleType[]): Promise<ApiResponse<ModuleTemplate[]>> {
    return TemplateModuleServiceBase.getAll({ url: '/v1/template/modules', clientId: this._clientId, isLocked, isEnabled, types });
  }

  public getTemplate(moduleId: string, version: number | undefined = undefined): Promise<ApiResponse<ModuleTemplate>> {
    return TemplateModuleServiceBase.getById(`/v1/clients/${this._clientId}/template/modules/${moduleId}`, version);
  }

  public sortOrder(moduleIds: string[]): Promise<ApiResponse<boolean>> {
    return TemplateModuleServiceBase.sort(`/v1/clients/${this._clientId}/template/modules/sort`, moduleIds);
  }

  public getTemplateForms(moduleId: string, moduleSectionId: string | undefined = undefined): Promise<ApiResponse<ModuleTemplateForm[]>> {
    return TemplateModuleServiceBase.getForms(`/v1/clients/${this._clientId}/template/modules/${moduleId}/forms`, moduleSectionId);
  }

  public getAllTemplateForms(filter?: GetAllTemplatesRequest): Promise<ApiResponse<FormConfig[]>> {
    return TemplateModuleServiceBase.get(`/v1/clients/${this._clientId}/template/modules/form-templates`, { params: filter });
  }

  public removeTemplate(moduleId: string): Promise<ApiResponse<boolean>> {
    return TemplateModuleServiceBase.remove(`/v1/clients/${this._clientId}/template/modules/${moduleId}/remove`);
  }

  public lockTemplate(moduleId: string): Promise<ApiResponse<boolean | ModuleTemplate>> {
    return TemplateModuleServiceBase.lock(`/v1/clients/${this._clientId}/template/modules/${moduleId}/disable`, 'delete');
  }

  public unlockTemplate(moduleId: string): Promise<ApiResponse<boolean | ModuleTemplate>> {
    return TemplateModuleServiceBase.unlock(`/v1/clients/${this._clientId}/template/modules/${moduleId}/enable`, 'put');
  }

  // #endregion

  public getTemplatesConfigurations(type?: ModuleType, templateModuleId?: string): Promise<ApiResponse<ClientModuleTemplateDefault[]>> {
    return TemplateModuleServiceBase.get(`/v1/clients/${this._clientId}/template/modules/configuration`, {
      params: { type: type, templateModuleId: templateModuleId, pageSize: 1000 },
    });
  }

  public importTemplate(moduleIds: string | string[]): Promise<ApiResponse<ModuleTemplate>> {
    if (!Array.isArray(moduleIds)) {
      moduleIds = [moduleIds];
    }
    return TemplateModuleServiceBase.post(`/v1/clients/${this._clientId}/template/modules/import`, moduleIds);
  }

  public importTemplatesAndInstantiate(moduleIds: string | string[]): Promise<ApiResponse<ModuleTemplate>> {
    if (!Array.isArray(moduleIds)) {
      moduleIds = [moduleIds];
    }
    return TemplateModuleServiceBase.post(`/v1/clients/${this._clientId}/template/modules/import-and-instantiate`, { moduleIds: moduleIds });
  }

  public createTemplateAndInstantiate(data: ModuleTemplate): Promise<ApiResponse<ModuleTemplate>> {
    return TemplateModuleServiceBase.create(`/v1/clients/${this._clientId}/template/modules/create-and-instantiate`, data);
  }

  public createModuleDefaults(
    moduleId: string,
    configuration: ModuleDefaultConfiguration,
  ): Promise<ApiResponse<ClientTemplateModuleDefaultResponse>> {
    return TemplateModuleServiceBase.post(`/v1/clients/${this._clientId}/template/modules/${moduleId}/defaults`, { configuration: configuration });
  }

  public getAllTemplateFormDefaults(request: FormTemplateConfigurationPagedFilter): Promise<ApiResponse<ClientFormDefault[]>> {
    return TemplateModuleServiceBase.get(`/v1/clients/${this._clientId}/template/modules/form-templates/configuration`, { params: request });
  }

  public getTemplateFormDefaults(request: FormTemplateConfigurationPagedFilter): Promise<ApiResponse<ClientFormDefault[]>> {
    return TemplateModuleServiceBase.get(`/v1/clients/${this._clientId}/template/modules/${request.moduleId}/form-templates/configuration`, {
      params: request,
    });
  }

  public moveTemplateForm(moduleId: string, request: MoveTemplateFormInModulesRequest): Promise<ApiResponse<boolean>> {
    return TemplateModuleServiceBase.put(`/v1/clients/${this._clientId}/template/modules/${moduleId}/move-template-form`, request);
  }
}

export default ClientTemplateModuleService;
