/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from '../models/ApiResponse';
import { ModuleTemplate, ModuleTemplateForm, ModuleType } from '../models/Module';
import BaseService from './BaseService';

export default class TemplateModuleServiceBase extends BaseService {
  protected static create(url: string, data: ModuleTemplate): Promise<ApiResponse<ModuleTemplate>> {
    return this.post(url, data);
  }

  protected static update(url: string, data: ModuleTemplate): Promise<ApiResponse<ModuleTemplate>> {
    return this.put(url, data);
  }

  protected static getAll({
    url,
    clientId,
    isLocked,
    isEnabled,
    types,
  }: {
    url: string;
    clientId?: string;
    isLocked?: boolean;
    isEnabled?: boolean;
    types?: ModuleType[];
  }): Promise<ApiResponse<ModuleTemplate[]>> {
    return this.get(url, { params: { clientId, isLocked, isEnabled, types: types || [ModuleType.Default, ModuleType.Document] } });
  }

  protected static getById(url: string, version: number | undefined = undefined): Promise<ApiResponse<ModuleTemplate>> {
    return this.get(url, { params: { version } });
  }

  protected static sort(url: string, moduleIds: string[]): Promise<ApiResponse<boolean>> {
    return this.put(url, moduleIds);
  }

  protected static getForms(url: string, moduleSectionId: string | undefined = undefined): Promise<ApiResponse<ModuleTemplateForm[]>> {
    return this.get(url, { params: { moduleSectionId } });
  }

  protected static remove(url: string): Promise<ApiResponse<boolean>> {
    return this.delete(url);
  }

  protected static lock(url: string, method: string, data?: ModuleTemplate): Promise<ApiResponse<boolean | ModuleTemplate>> {
    return (this as any)[method](url, data);
  }

  protected static unlock(url: string, method: string, data?: ModuleTemplate): Promise<ApiResponse<boolean | ModuleTemplate>> {
    return (this as any)[method](url, data);
  }
}
